import React from 'react'

function PageNotFound(props) {
    return (
        <div>
            <h1>404</h1>
        </div>
    )
}

export default PageNotFound

